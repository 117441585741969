<template>
    <BaseCard
        v-motion
        class="property-card"
        :link="property.propertyPhase === 'inDevelopment' ? '/in-the-making' : property.uri"
        :class="property.propertyPhase === 'comingSoon' ? 'property-list__item--coming-soon' : ''"
        :images="[
            property.mainImage || null,
            property.hoverImage || null
        ]"
        :initial="{ opacity: 0 }"
        :enter="{ 
            opacity: 1,
            transition: {
                duration: 1000,
                ease: '0.4, 0, 0.2, 1',
                delay: `${index * 100}`
            }
        }"
    >
        <template
            v-if="property.propertyPhase === 'comingSoon'"
            #ticker
        >
            {{ $t('coming-soon') }}
        </template>
        <template #header>
            <DashedText
                v-if="property.city?.length"
                element="h3"
            >
                <template #left>
                    {{ property.city?.[0]?.title }}
                </template>
                <template #right>
                    <template v-if="property.area && property.propertyPhase === 'open'">
                        {{ property.area }}
                    </template>
                    <template v-else-if="property.propertyPhase === 'inDevelopment'">
                        {{ $t('in-development') }}
                    </template>
                    <template v-else-if="property.propertyPhase === 'comingSoon'">
                        {{ $t('coming-soon') }}
                    </template>
                </template>
            </DashedText>
        </template>

        <template
            v-if="property.facilities"
            #description
        >
            <BaseIconList
                v-if="property?.facilities && property.facilities.length"
                :items="averageRating ? property?.facilities.slice(0, 3) : property?.facilities"
                variant="list"
            >
                <template
                    v-if="loadingRating || averageRating"
                    #default
                >
                    <BaseIcon
                        icon="star"
                        class="base-list__icon"
                    />
                    <div
                        class="base-list__title"
                    >
                        <template
                            v-if="loadingRating"
                        >
                            Loading reviews...
                        </template>
                        <template v-else>
                            Rated {{ averageRating }} by guests
                        </template>
                    </div>
                </template>
            </BaseIconList>
            <!-- {{ property.facilities?.map(({ title }) => title).join(', ') }} -->
        </template>

        <template
            v-else-if="property.description"
            #description
        >
            {{ property.description }}
        </template>

        <template #link>
            {{ $t('explore') }}
        </template>
    </BaseCard>
</template>

<script setup>
import useReviews from '~/composables/useReviews';

const props = defineProps({
    property: {
        type: Object,
        required: true,
        default: () => ({})
    },
    index: {
        type: Number,
        required: true,
        default: 1
    }
});

const { getAverageRating } = useReviews();

const averageRating = ref(null);
const loadingRating = ref(true);

onMounted(async() => {
    if (props.property?.slug) {
        const result = await getAverageRating(props.property.slug);

        if (result) {
            averageRating.value = result?.averageRating;
        }

        loadingRating.value = false;
    }
});
</script>

<style lang="less">
.property-card {
    border: 1px dotted #0f0;
    opacity: 1;
    transition: opacity var(--transition-timing-medium) var(--transition-timingFunction-in-out);
    width: calc((100vw - var(--filters-width) - (var(--grid-gutter-margin) * 3)) / 3);
    min-width: 14.375rem;

    .base-image {
        display: inline;
    }

    @media ( min-width: @max-page-width ) {
        width: calc(((var(--grid-maxWidth-page) - (var(--grid-gutter-margin) * 2) - var(--filters-width)) / 3) - (var(--slider-gap) / 1.5));
        height: 100%;

        .base-card__content {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            .base-card__body {
                flex: 1 0 auto;
            }
        }
    }

    h3 {
        .typography-h4();
        font-size: var(--font-size-lg);

        @media @q-md-min {
            font-size: var(--font-size-lg);
        }
    }
}
</style>
